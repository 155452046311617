<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>
        </v-app-bar>
        
        <v-card-text class="row content mx-0">
            <v-row align-content="center" justify="center" no-gutters style="width:100%;">
                <v-col cols="12" md="10" lg="7" xl="6" :class="$vuetify.breakpoint.smAndDown ? 'mb-10' : ''">

                    <v-card-title class="headline pl-0" style="word-break: break-word;">What do you want to do next?</v-card-title>
                    <v-card-subtitle class="pl-0">Please select an option below to continue</v-card-subtitle>

                    <v-row>
                        <v-col cols="12">
                            <v-card flat color="grey lighten-4" class="hover" to="/checkout">
                                <v-card-text>
                                    <div class="title">Go to checkout</div>
                                    <div>Complete your order paying with credit card or Paypal</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card flat color="grey lighten-4" class="hover" to="/stylist-review">
                                <v-card-text>
                                    <div class="title">Review order with stylist</div>
                                    <div>Make sure that everything is correct before completing your order</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card flat color="grey lighten-4" class="hover" to="/checkout-cashier">
                                <v-card-text>
                                    <div class="title">Pay at cashier</div>
                                    <div>Finish your order at the store cashier</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>
                    </v-row>

                </v-col>

                <v-btn absolute bottom left depressed x-large color="error" @click="restart" :icon="$vuetify.breakpoint.smAndDown">
                    <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-restart</v-icon> 
                    <span v-if="!$vuetify.breakpoint.smAndDown">Restart</span>
                </v-btn>

            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}

.hover:hover {
    background-color: #e7e7e7 !important;
}
</style>